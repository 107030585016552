import React, { useContext, useEffect, useRef, useState } from "react";
import store from "store";

import { CompanyFormationsConfigResponse, UpsellProductType } from "Apis/ConfigurationApi";
import { CountryOfRegistrationEnum } from "../Pages/OfficeAddressForm/CountryOfRegistrationEnum";
import { OfficeAddressKind } from "Pages/OfficeAddressForm/OfficeAddressType";
import { VirtualOfficeAddress } from "Pages/OfficeAddressForm/VirtualOfficeAddress";
import history from "../appHistory";
import { defaultStakeholder, Stakeholder } from "../Types/PersonalInformation";

import { companyFormationSteps, FormState, StepperState, StepRoutes } from "./StepperState";

interface StepperContext {
    currentStep: StepperState;
    formState: FormState;
    config: CompanyFormationsConfigResponse | null;
    showContinueButton: boolean,
    disableContinueButton: boolean;
    isLoading: boolean;
    scrollBarElementRef;
    proceedLabel;
    hasZemplerProduct: boolean,
    primaryStakeholder: Stakeholder;

    resetContext(): void;

    setCurrentStep(currentStep: StepperState): void;

    navigateToStep(step: StepRoutes | undefined): void;

    changeFormState(key: keyof FormState, value): void;

    changePrimaryStakeholderState(key: keyof Stakeholder, value): void;

    setShowContinueButton(show: boolean): void;

    setDisableContinueButton(disabled: boolean): void;

    navigateToNextStep(): void;

    navigateToPreviousStep(): void;

    setIsLoading(value: boolean): void;

    setProceedLabel(label: string): void;
}

const CompanyFormationsContext = React.createContext<StepperContext>({} as StepperContext);
const useCompanyFormationsContext = () => useContext(CompanyFormationsContext);

interface Props {
    config: CompanyFormationsConfigResponse | null,
    children: React.ReactNode,
}

const CompanyFormationsProvider = ({
    children,
    config,
}: Props) => {
    const storeFormStateKey = "__companyFormations.formStateKey";
    const storeCurrentStepKey = "__companyFormations.currentStepKey";
    const scrollBarElementRef = useRef(null);
    const defaults = {
        step: companyFormationSteps[0],
        formState: {
            companyTitle: "Ltd",
            officeAddressType: OfficeAddressKind.VirtualAddress,
            bscUpsellBasketIds: [],
            stakeholders: [{
                ...defaultStakeholder("Director"),
                isPrimary: true,
                shareCount: 100,
                typeCount: 1,
                personSignificantControl: true,
            }],
        } as FormState,
    };
    const [currentStep, setCurrentStep] = useState<StepperState>(store.get(storeCurrentStepKey) ?? defaults.step);
    const [formState, setFormState] = useState<FormState>(store.get(storeFormStateKey) ?? defaults.formState);
    const [showContinueButton, setShowContinueButton] = useState(true);
    const [disableContinueButton, setDisableContinueButton] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [proceedLabel, setProceedLabel] = useState("Continue");

    useEffect(() => {
        history.listen(location => {
            const newStep = companyFormationSteps.find(x => x.currentPage === location.pathname);
            if (newStep) {
                setCurrentStep({ ...newStep } as StepperState);
                if (config?.configuration.saveToLocalLocalStorage) {
                    store.set(storeCurrentStepKey, { ...newStep });
                }
            }
        });
    }, []);

    const changeFormState = (key: keyof FormState, value) => {
        if (config?.configuration.saveToLocalLocalStorage) {
            store.set(storeFormStateKey, {
                ...formState,
                [key]: value,
            });
        }
        setFormState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const changePrimaryStakeholderState = (field: keyof Stakeholder, value: any) => {
        if (config?.configuration.saveToLocalLocalStorage) {
            store.set(storeFormStateKey, {
                ...formState,
                stakeholders: formState.stakeholders
                    .map(x => (
                        x.isPrimary
                            ? {
                                ...x,
                                [field]: value,
                            }
                            : x)),
            });
        }

        setFormState(prev => ({
            ...prev,
            stakeholders: prev.stakeholders
                .map(x => (
                    x.isPrimary
                        ? {
                            ...x,
                            [field]: value,
                        } : x)),
        }));
    };

    const getUpsellProductById = (productId: string): { product: UpsellProductType, skipRegisteredOfficeStep: boolean, upsellContainsVirtualAddress: boolean } | undefined => {
        const allProducts = config?.upsells.filter(x => x.pageUri !== "/registered-office")
            .reduce((accumulator, currentValue) => {
                currentValue.upsells.forEach(upsell => {
                    upsell.products.forEach(product => {
                        accumulator.push({
                            product,
                            skipRegisteredOfficeStep: upsell.skipRegisteredOfficeStep,
                            upsellContainsVirtualAddress: upsell.upsellContainsVirtualAddress,
                        });
                    });
                });
                return accumulator;
            }, [] as { product: UpsellProductType, skipRegisteredOfficeStep: boolean, upsellContainsVirtualAddress: boolean }[]) ?? [];

        return allProducts?.find(x => x.product.id === productId);
    };

    const navigateToStep = (step: StepRoutes | undefined, previous: boolean = false) => {
        if (step === "/shareholdings" || step === "/psc") {
            const directors = formState.stakeholders
                .filter(x => x.type === "Director");

            if (!directors || directors.length <= 1) {
                // If we only have one director, skip shareholdings page
                const pageOfInterest = companyFormationSteps
                    .find(x => x.currentPage === step);

                navigateToStep(previous ? pageOfInterest?.previousPage : pageOfInterest?.nextPage, previous);
                return;
            }
        }

        if (step === "/business-bank-account") {
            const bankAccountUpsells = config?.upsells
                .find(x => x.pageUri === step)
                ?.upsells ?? [];

            if (!bankAccountUpsells || bankAccountUpsells.length <= 0) {
                // If we don't have any bank account upsells, skip the page
                const bankAccountPage = companyFormationSteps
                    .find(x => x.currentPage === step);

                navigateToStep(previous ? bankAccountPage?.previousPage : bankAccountPage?.nextPage, previous);
                return;
            }
        }

        if (step === "/kyc") { // Skip KYC for now
            const kycPage = companyFormationSteps.find(x => x.currentPage === step);
            navigateToStep(previous ? kycPage?.previousPage : kycPage?.nextPage, previous);
            return;
        }

        if (step) {
            history.push(step, { from: currentStep.currentPage });
            return;
        }

        history.push("/");
    };

    const navigateToNextStep = () => {
        setShowContinueButton(true);

        if (currentStep.currentPage === "/security-information") {
            let shouldSkipStep = false;
            formState.bscUpsellBasketIds.forEach(x => {
                const product = getUpsellProductById(x);
                if (product?.skipRegisteredOfficeStep && product?.upsellContainsVirtualAddress) {
                    shouldSkipStep = true;
                }
            });
            if (shouldSkipStep) {
                changeFormState("officeAddressType", OfficeAddressKind.VirtualAddress);
                changeFormState("officeAddress", VirtualOfficeAddress);
                changeFormState("countryOfRegistrationKind", CountryOfRegistrationEnum.EnglandAndWales);
                history.push("/business-category");
                return;
            }
        }

        navigateToStep(currentStep.nextPage);
    };

    const navigateToPreviousStep = () => {
        navigateToStep(currentStep.previousPage, true);
    };

    const resetContext = () => {
        setCurrentStep(defaults.step);
        store.remove(storeCurrentStepKey);
        setFormState(defaults.formState);
        store.remove(storeFormStateKey);
    };

    const hasZemplerProduct = () => {
        const zemplerProductIds: string[] | undefined = config?.upsells
            .flatMap(upsellPage => upsellPage.upsells)
            .filter(upsell => upsell.isZemplerProduct)
            .flatMap(upsell => upsell.products.map(product => product.id));

        return formState.bscUpsellBasketIds && formState.bscUpsellBasketIds
            .some(basketId => zemplerProductIds?.includes(basketId));
    };

    const primaryStakeholder = formState.stakeholders.find(x => x.isPrimary) ?? {} as Stakeholder;

    return (
        <CompanyFormationsContext.Provider value={{
            resetContext,
            currentStep,
            setCurrentStep,
            navigateToStep,
            formState,
            changeFormState,
            config,
            showContinueButton,
            setShowContinueButton,
            disableContinueButton,
            setDisableContinueButton,
            navigateToNextStep,
            navigateToPreviousStep,
            isLoading,
            setIsLoading,
            scrollBarElementRef,
            setProceedLabel,
            proceedLabel,
            hasZemplerProduct: hasZemplerProduct(),
            primaryStakeholder,
            changePrimaryStakeholderState,
        }}
        >
            {children}
        </CompanyFormationsContext.Provider>);
};

export {
    useCompanyFormationsContext,
    CompanyFormationsProvider,
};
