import React from "react";
import PropTypes from "prop-types";

import Checkbox from "../Checkbox";
import api from "../../Utils/api";

const TermsAndConditions = ({
    checked,
    businessName,
    onToggle,
    termsAndConditionsLink,
}) => {
    const business = businessName || "the Business Support Club";
    return (
        <div className="d-flex align-items-center">
            <Checkbox
                testId="acceptTermsCheckbox"
                id={`terms-${businessName}`}
                checked={checked}
                onChange={onToggle}
                className="d-inline-block mr-0"
            />
            <strong>
                I agree to the&nbsp;
                <a
                    href={termsAndConditionsLink ?? api.configuration.termsAndConditionsLink}
                    rel="noopener noreferrer"
                    className="tw-text-bsc-blue hover:tw-text-bsc-blue"
                    target="_blank"
                >
                    Terms and Conditions
                </a>
                <span>
                    &nbsp;and&nbsp;
                    <a
                        href={api.configuration.privacyPolicyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tw-text-bsc-blue hover:tw-text-bsc-blue"
                    >
                        Privacy Policy
                    </a>
                </span>
                &nbsp;of {business}.
            </strong>
        </div>
    );
};

TermsAndConditions.propTypes = {
    businessName: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    termsAndConditionsLink: PropTypes.string,
};

export { TermsAndConditions };
